import Vue from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import axios from 'axios';
import VueI18n from 'vue-i18n';
import { languages } from '../public/index.js';
import { defaultLocale } from '../public/index.js';
import fullscreen from 'vue-fullscreen';
import VueCookies from 'vue-cookies';
import titleMixin from './router/TitleMixin.js';

Vue.use(VueCookies);
Vue.use(VueI18n);
Vue.config.productionTip = false;
Vue.use(fullscreen);
Vue.mixin(titleMixin);

const messages = Object.assign(languages);

var i18n = new VueI18n({
  locale: defaultLocale,
  fallbackLocale: 'en',
  messages
})

axios.get('./config.json')
    .then((response) => {
        if (response.data)
            Vue.prototype.$config = response.data;
    })
    .catch((error) => {
        console.log('Get config.json failed:', error);
    })
    .finally(() => {
        new Vue({
            i18n,
            router,
            vuetify,
            render: h => h(App)
        }).$mount('#app');
    });
   
//(async function init() {
//    try {
//        const response = await axios.get('./config.json');
//        Vue.prototype.$config = response.data;
//    }
//    catch (e) {
//        console.log('Get config.json failed:', e);
//    }
//
//    new Vue({
//        router,
//        vuetify,
//        render: h => h(App)
//    }).$mount('#app');
//})();
 
