<template>
    <div>
        <fullscreen ref="fullscreen" @change="fullscreenChange">
            <v-navigation-drawer v-if="drawer" class="res-menu" fixed mini-variant mini-variant-width="90"
                @mouseover.native="enableDrawer" mobile-breakpoint @mouseout.native="disableDrawer" bottom absolute>
                <template v-slot:prepend>
                    <v-list nav class="res-zen">
                        <v-list-item :ripple="false" to="/" class="res-img1">
                            <v-list-item-avatar v-if="!mobile" tile>
                                <v-img src="../assets/zenetys.png" class="res-img" />
                            </v-list-item-avatar>
                            <div class="d-flex">
                                <v-list-item-icon>
                                    <v-img alt="Logo" class="shrink mr-2" contain src="../assets/zenetys.png"
                                        transition="scale-transition" width="30" />
                                    <v-toolbar-title small class="text-overline">
                                        Zenetys
                                    </v-toolbar-title>
                                </v-list-item-icon>
                            </div>
                        </v-list-item>
                    </v-list>
                </template>

                <v-list nav>
                    <v-list-item-group>
                        <v-list-item v-for="app in this.$config" :key="app.name" :ripple="false"
                            v-show="app.type === 'iframe'" @click=gotoApp(app)
                            :to="{ name: 'App', params: { name: app.name } }" class="left">
                            <v-list-item-icon style="padding-top:-10px !important ;">
                                <v-tooltip bottom open-delay="700" :open-on-hover="true">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon v-if="!mobile" large v-on="on" v-bind="attrs" v-text="app.icon">
                                        </v-icon>
                                        <v-icon v-else v-text="app.icon">
                                        </v-icon>
                                    </template>
                                    <span v-text="app.name"></span>
                                </v-tooltip>
                                <v-list-item-content v-if="mobile" class="res-icon-text">
                                    <v-list-item-title v-text="app.name"
                                        class="text-overline text--secondary res-icon-text">
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item-icon>
                        </v-list-item>

                        <v-list-item v-for="app in this.$config" :key="app.icon" :ripple="false"
                            v-show="app.type === 'window'" @click=gotoApp(app) class="left">
                            <v-list-item-icon>
                                <v-tooltip bottom open-delay="700" :open-on-hover="true">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon v-if="!mobile" v-text="app.icon" large v-on="on" v-bind="attrs">
                                        </v-icon>
                                        <v-icon v-else v-text="app.icon">
                                        </v-icon>
                                    </template>
                                    <span v-text="app.name"></span>
                                </v-tooltip>
                                <v-list-item-content v-show="mobile" class="res-icon-text">
                                    <v-list-item-title v-text="app.name"
                                        class="text-overline text--secondary res-icon-text">
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item-icon>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>

                <template v-if="!mobile" v-slot:append>
                    <v-list nav>
                        <v-list-item-group>
                            <v-list-item :ripple="false" @click="toggle">
                                <v-list-item-icon>
                                    <v-icon large>
                                        mdi-fullscreen
                                    </v-icon>
                                </v-list-item-icon>
                            </v-list-item>
                            <v-list-item>
                                <v-dialog v-model="connexionDialog" max-width="600px">
                                    <template v-slot:activator="{ on }">
                                        <v-list-item-avatar v-on="on">
                                            <v-icon class="background-icon" large>
                                                mdi-account
                                            </v-icon>
                                        </v-list-item-avatar>
                                    </template>
                                    <AppConnexion @closeDialog="closeD($event)"></AppConnexion>
                                </v-dialog>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </template>

            </v-navigation-drawer>

            <div v-if="!mobile" class="z-navigation-opener" @mouseover="enableDrawer">
                <v-list-item v-for="n in 3" v-show="drawer === false" :ripple="false" :key="n" class="transparent-icon">
                    <v-icon> mdi-chevron-right </v-icon>
                </v-list-item>
            </div>

            <v-app-bar v-if="mobile" elevation="0" color="#fff" app>
                <v-app-bar-nav-icon @click="drawer = !drawer" class="res-icon"></v-app-bar-nav-icon>
                <v-spacer></v-spacer>
                <v-toolbar-title large>
                    Zenetys Tools
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-dialog v-model="connexionDialog" max-width="600px">
                    <template v-slot:activator="{ on }">
                        <v-avatar text v-on="on">
                            <v-icon class="background-icon" large> mdi-account</v-icon>
                        </v-avatar>
                    </template>
                    <AppConnexion @closeDialog="closeD($event)"></AppConnexion>
                </v-dialog>
            </v-app-bar>


        </fullscreen>
    </div>
</template>

<style >
html {
    overflow-y: hidden !important;
}

.z-navigation-opener {
    width: 10px;
    height: 100vh;
    position: fixed;
    z-index: 99;
}

.v-application a {
    text-decoration: none;
    color: black !important;
}

/* user icon */
.background-icon {
    color: grey;
    background-color: #4DD0E1;
}


.v-navigation-drawer--mini-variant .v-list-item>*:first-child {
    margin-right: 0;
}

.transparent-icon {
    opacity: 0.5;
    top: 40%;
    margin-bottom: -30px;
    margin-left: -20px;
}

.res-zen {
    margin-bottom: 20px !important;
}

@media only screen and (max-width: 600px) {
    .res-menu {
        margin-top: 56px;
        width: 190px !important;
    }

    .left {
        justify-content: left !important;
        height: -10px;

    }

    .res-icon-text {
        padding-left: 5px !important;
    }

    .res-img {
        width: 30px !important;
        height: 30px !important;
    }

    .res-img1 {
        justify-content: left !important;
        height: 50px !important;
    }

    .theme--light.v-application .v-list-item--active .text--secondary {
        color: black !important;
    }

    .v-list-item__content {
        padding: 0px;
    }

    .v-list-item__icon {
        margin: 10px 0;
    }
}
</style>

<script>
import AppConnexion from './AppConnexion.vue';


export default {
    name: 'PanelBar',
    data: () => ({
        connexionDialog: false,
        timer: null,
        drawer: false,
        menuEntries: null,
        url: null,
        fullscreen: false,
        res: "mb-6",
    }),
    components: { AppConnexion },
    methods: {
        enableDrawer() {
            if (this.mobile === false) {
                clearTimeout(this.timer);
                this.drawer = true;
            }
        },
        disableDrawer() {
            clearTimeout(this.timer);
            if (this.mobile == false) {
                this.timer = setTimeout(() => {
                    if (this.connexionDialog == false)
                        this.drawer = false;
                }, 100);
            }
        },
        gotoApp(configEntry) {
            if (configEntry.type === "iframe")
                this.$router.push({ path: '/App/' + configEntry.name });
            else if (configEntry.type === "window")
                window.open(configEntry.url);
            this.$router.go()
        },
        closeD(connexionDialog) {
            this.connexionDialog = connexionDialog;
            if (this.connexionDialog == false)
                this.drawer = false;
        },
        toggle() {
            this.$refs['fullscreen'].toggle();
        },
        fullscreenChange(fullscreen) {
            this.fullscreen = fullscreen;
        },
        onClickOutsideStandard() {
            if (this.drawer == true)
                this.drawer = false
        },
    },
    computed: {
        mobile() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs': return true;
            } return false
        },

    },

};
</script>
