import Vue from 'vue';
import Router from 'vue-router';
import HomePage from '@/components/HomePage';
import App  from '@/components/App';

Vue.use(Router);

export default new Router({
  routes: [   
    {
      path: '/App/:name',
      name: 'App',
      component: App,
    },
    {
      path: '/',
      name: 'HomePage',
      component: HomePage
    },
  ]
});
