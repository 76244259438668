<template>
    <v-app>
        <!--------- HEADER-BAR --------->
        <v-app-bar elevation="0" color="#fff" app>
            <div class="d-flex align-center">
                <v-img alt="Logo" class="shrink mr-2" contain src="../assets/zenetys.png" transition="scale-transition"
                    width="40" />
                <v-toolbar-title large>
                    Zenetys Tools
                </v-toolbar-title>
            </div>
            <v-spacer></v-spacer>
            <v-dialog v-model="connexionDialog" max-width="600px">
                <template v-slot:activator="{ on }">
                    <v-avatar text v-on="on">
                        <v-icon class="background-icon" large> mdi-account</v-icon>
                    </v-avatar>
                </template>
                <AppConnexion @closeDialog="closeD($event)"></AppConnexion>
            </v-dialog>
        </v-app-bar>
        <v-main>
            <!-- TOOLS -->
            <v-card class="mx-auto global_card" outlined color="transparent">
                <v-container fluid>
                    <v-row class="row" dense no-gutters>
                        <v-col v-for="appEntry in this.$config" :key="appEntry.name" :cols="col">
                            <v-hover v-slot="{ hover }">
                                <v-card :ripple="false" @click="gotoApp(appEntry)" :class="{ 'on-hover': hover }"
                                    :elevation="hover ? 12 : 2" class="app_card">
                                    <v-icon class="app_icon" x-large color="white">
                                        {{ appEntry.icon }}
                                    </v-icon>
                                    <v-btn class="app_btn" outlined color="white white--text" v-text="appEntry.name">
                                    </v-btn>
                                </v-card>
                            </v-hover>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </v-main>

    </v-app>
</template>

<style>
html {
    overflow-y: auto;
}

.v-main__wrap {
    background-color: #e5e5e5;
    font-size: larger;
}

.v-btn {
    border-color: white;
}

.background-icon {
    color: grey;
    padding: 10px;
    background-color: #4DD0E1;
}

.app_btn {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 160px;
}

.app_icon {
    padding: 30px;
    background-color: #4DD0E1;
    border-radius: 90px !important;
    position: absolute !important;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 40px;
}

.app_card {
    border-radius: 0px !important;
    margin: 5px !important;
    background-color: #26C6DA !important;
    height: 250px;
}

.on-hover {
    filter: brightness(95%);
}

.global_card {
    margin-top: 130px;
    width: 600px;
    background-color: transparent;

}

@media only screen and (max-width: 600px) {
    .global_card {
        margin-bottom: 5%;
        margin-top: 10%;
    }

    .app_card {
        height: 18vh;
        margin: 4px !important;
    }

    .app_icon {
        margin-top: 5vh;
        margin-left: 8vh;
        padding: 2vh;
        z-index: 99;
    }

    .app_btn {
        left: 25%;
        margin-top: 7vh !important;
    }

    .row {
        justify-content: center;
    }

    html {
        overflow-y: visible;
    }
}
</style>

<script>
import AppConnexion from './AppConnexion.vue';
export default {
    name: "HomePage",
    data: () => ({
        url: null,
        connexionDialog: false,
        langs: ['fr', 'en'],
        result: '',
    }),
    components: { AppConnexion },
    methods: {
        gotoApp(configEntry) {
            if (configEntry.type === "iframe")
                this.$router.push({ path: '/App/' + configEntry.name });
            else if (configEntry.type === "window")
                window.open(configEntry.url);
        },
        closeD(connexionDialog) {
            this.connexionDialog = connexionDialog;
        },
    },
    computed: {
        col() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs': return 8
            } return 6
        },
    },
};
</script>
