<template>
    <v-card>
        <v-card-title>
            <span class="text-h5"> {{ $t('Log In :') }}</span>
        </v-card-title>
        <v-card-text>
            <v-container>
                <v-row>
                    <v-col cols="12">
                        <v-text-field :rules="emailRules" label="*E-mail :" required clearable></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field v-model="password" counter :rules="[rules.required, rules.min]"
                            :label="$t('*Password :')" type="password" required clearable></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-select v-model="$i18n.locale" :label="$t('languages')" :items="langs" @change="onChange()">
                        </v-select>
                    </v-col>
                </v-row>
            </v-container>
            <small> {{ $t('*indicates required field') }} </small>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="passEvent">
                {{ $t('cancel') }}
            </v-btn>
            <v-btn color="blue darken-1" text @click="passEvent">
                {{ $t('confirm') }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import VueCookies from 'vue-cookies';
export default {
    name: 'AppConnexion',
    data: () => ({
        connexionDialog: false,
        localStorage: null,
        password: '',
        rules: {
            required: value => !!value || 'Required.',
            min: v => v && v.length >= 8 || 'Min 8 characters',
        },
        emailRules: [
            v => !!v || 'E-mail is required',
            v => /.+@.+/.test(v) || 'E-mail must be valid',
        ],
        langs: ['fr', 'en']
    }),
    methods: {
        passEvent() {
            this.$emit('closeDialog', this.connexionDialog)
        },
        onChange() {
            VueCookies.set('lang', this.$i18n.locale);
        },
    },
};

</script>