<template>
    <v-app>
        <PanelBar>
        </PanelBar>

        <Iframe>
        </Iframe>
    </v-app>
</template>

<style scoped>
</style>

<script>
import PanelBar from "./PanelBar.vue";
import Iframe from "./Iframe.vue";

export default {
    name: 'App',
    components: { PanelBar, Iframe },
    data: () => ({
    }),
}
</script>