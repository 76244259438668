<template>
    <fullscreen ref="fullscreen" @change="fullscreenChange">
        <v-app>
            <v-main>
                <iframe name="iframe" class="frame" :src="url" frameborder="0"></iframe>
            </v-main>
        </v-app>
    </fullscreen>
</template>
<style scoped>
.frame {
    width: 100%;
    height: 100vh;
}
</style>

<script>
export default {
    title() {
        this.getData();
        return `Zenetys Tools : ${this.titleP}`
    },
    name: 'App',
    appEntries: null,
    data: () => ({
        url: null,
        titleP: null,
        fullscreen: false,
    }),
    methods: {
        getData() {
            const appEntries = this.$config.find((obj) => {
                return obj.name == this.$route.params.name;
            });
            if (appEntries) {
                this.url = appEntries.url;
                this.titleP = appEntries.name;
            }
        },
        toggle() {
            this.$refs['fullscreen'].toggle();
        },
        fullscreenChange(fullscreen) {
            this.fullscreen = fullscreen;
        },
    },
    mounted() {
        this.getData();
    },
};
</script>